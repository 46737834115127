import React from 'react'
import PropTypes from 'prop-types'
import InstagramEmbed from 'react-instagram-embed'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <h2>Here's some of my favorite work:</h2>
          <ul>
            <li>
              <strong>
                <a href="https://www.dailydot.com/unclick/poppy-youtube-cult/">
                  The cult appeal of Poppy, the perfect pop idol of the future
                </a>
              </strong>{' '}
              (Daily Dot, 2017)
            </li>
            <li>
              <strong>
                <a href="http://kernelmag.dailydot.com/issue-sections/headline-story/16806/useless-unsuccessful-unpopular-uuuu-anti-memes/">
                  Inside the topsy-turvy world of Useless, Unsuccessful, and/or
                  Unpopular Memes
                </a>
              </strong>{' '}
              (The Kernel, 2016)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://www.dailydot.com/lol/bring-back-josta-quest-pepsi-90s/"
                >
                  Chasing the Panther: Behind the Decade-Long Quest to Bring
                  Back Josta
                </a>
              </strong>{' '}
              (Daily Dot, 2016)
            </li>
            <li>
              <a
                target="_blank"
                href="https://features.wearemel.com/how-to-prosecute-an-internet-troll-827e29c621c5#.rf1fy3pwd"
              >
                <strong>How to Prosecute an Internet Troll</strong>
              </a>{' '}
              (MEL Magazine, 2016)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://nymag.com/following/2016/02/facebook-enough-with-the-forced-memories.html"
                >
                  Facebook: Enough With the Forced Memories
                </a>
              </strong>{' '}
              (NYMag, 2016)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://gawker.com/did-airport-security-grope-morrisseys-penis-and-testicl-1734993525"
                >
                  Did Airport Security Grope Morrissey's Penis and Testicles?
                </a>
              </strong>
              &nbsp;(Gawker, 2015)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://gawker.com/how-4chan-trolled-two-of-its-friends-by-framing-them-fo-1734265649"
                >
                  How 4chan Trolled Two of Its Friends by Framing Them for the
                  Oregon Mass Shooting
                </a>{' '}
              </strong>
              (Gawker, 2015)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://defamer.gawker.com/happy-ending-enthusiasts-review-the-massage-parlor-wher-1730122536"
                >
                  Happy Ending Enthusiasts Review the Massage Parlor Where
                  Calvin Harris Did Something for Two Hours
                </a>
              </strong>{' '}
              (Gawker, 2015)
            </li>
            <li>
              <a
                target="_blank"
                href="http://gawker.com/a-guide-to-the-sex-discrimination-lawsuit-thats-roiling-1689849734"
              >
                <strong>
                  A Guide to the Sex Discrimination Lawsuit That's Roiling
                  Silicon Valley
                </strong>
              </a>{' '}
              (Gawker, 2015)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://gawker.com/donald-trumps-grossly-exaggerated-net-worth-a-timeline-1711718182"
                >
                  Donald Trump's Grossly Exaggerated Net Worth: A Timeline
                </a>
              </strong>{' '}
              (Gawker, 2015)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://gawker.com/i-ate-burger-king-japans-black-cheeseburger-and-the-mcd-1642725148"
                >
                  I Ate Burger King Japan's Black Cheeseburger—and the
                  McDonald's Version, Too
                </a>
              </strong>{' '}
              (Gawker, 2014)
            </li>
            <li>
              <a
                target="_blank"
                href="http://gawker.com/what-is-gamergate-and-why-an-explainer-for-non-geeks-1642909080"
              >
                <strong>
                  What is Gamergate and Why? An Explainer for Non-Geeks
                </strong>
              </a>{' '}
              (Gawker, 2014)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://www.dailydot.com/entertainment/wonderwall-kitty-pryde-tumblr-interview/"
                >
                  Why Kitty Pryde Is More Than a Tumblr Rapper
                </a>
              </strong>
              &nbsp;(Daily Dot, 2013)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="https://web.archive.org/web/20120612155439/http://suicidegirls.com/interviews/2805/John-Linnell-They-Might-Be-Giants/"
                >
                  Interview With John Linnell of They Might Be Giants
                </a>
              </strong>{' '}
              (Suicide Girls, 2011)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://www.coudal.com/ftb/index.php?year=your&amp;author=hathaway2"
                >
                  Reading Cheever in New York
                </a>
              </strong>
              &nbsp;(Field-Tested Books, 2009)
            </li>
            <li>
              <strong>
                <a href="http://www.jayhathaway.com/cold-content-farm/">
                  Cold Content Farm
                </a>
                &nbsp;
              </strong>
              (Personal Blog, 2009)
            </li>
            <li>
              <strong>
                <a
                  target="_blank"
                  href="http://www.coudal.com/ftb/index.php?year=your&amp;author=hathaway"
                >
                  Reading Dune at the Zoo
                </a>{' '}
              </strong>
              (Field-Tested Books, 2007)
            </li>
          </ul>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <h2>Right Now:</h2>
          <p>
            I'm a web developer. Check out{' '}
            <a href="http://www.jayhathaway.com/">my portfolio</a> and get in
            touch about projects.&nbsp;&nbsp;
          </p>
          <h2>Previously:</h2>
          <p>
            Senior writer,&nbsp;
            <a href="http://www.dailydot.com/authors/jay-hathaway/">
              Daily Dot
            </a>
            . I wrote about our increasingly terrible online culture, which is
            increasingly becoming real life.&nbsp;
          </p>
          <h2>Before That:</h2>
          <p>
            Worked on <a href="http://nymag.com/following/">Following</a>, a
            pop-up blog about life on the internet, for <em>New York</em>
            &nbsp;Magazine. It's now called{' '}
            <a href="http://nymag.com/selectall/">Select All</a>
            .&nbsp;
          </p>
          <p>
            Staff writer at <a href="https://kinja.com/jayhathaway">Gawker</a>,
            news editor at{' '}
            <a href="http://www.dailydot.com/authors/jay-hathaway/">
              the Daily Dot
            </a>
            , geek editor at{' '}
            <a href="https://web.archive.org/web/20120205082551/http://geeks.thedailywh.at/">
              The Daily What
            </a>
            &nbsp;(R.I.P.), writer at{' '}
            <a href="https://web.archive.org/web/20110616133313/http://www.urlesque.com/bloggers/jay-hathaway/">
              Urlesque
            </a>
            &nbsp;(R.I.P.), editor at{' '}
            <a href="https://web.archive.org/web/20100421014055/http://www.downloadsquad.com/bloggers/jay-hathaway/">
              Download Squad
            </a>
            &nbsp;(also R.I.P.) and interviewer of interesting people for{' '}
            <a href="https://web.archive.org/web/20120612155439/http://suicidegirls.com/interviews/2805/John-Linnell-They-Might-Be-Giants/">
              Suicide Girls
            </a>
            &nbsp;and <em>Geek Monthly.</em>
          </p>
          <h2>For What It's Worth:</h2>
          <p>
            Some of my jokes have been published in{' '}
            <a href="https://www.amazon.com/dp/B002LUHZB4/ref=dp-kindle-redirect?_encoding=UTF8&amp;btkr=1">
              a book of funny tweets
            </a>
            .&nbsp;Gawker{' '}
            <a href="http://gawker.com/391390/twelve-people-actually-worth-following-on-twitter">
              once called me
            </a>{' '}
            one of the "Twelve People Actually Worth Following On Twitter."
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            action="https://formspree.io/inquiries@jayhathaway.com"
            method="POST"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="http://www.twitter.com/strutting"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@strutting"
                className="icon fa-medium"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
        <article
          id="drake"
          className={`${this.props.article === 'drake' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">A Drake Video</h2>
          <InstagramEmbed
            url="https://www.instagram.com/p/9EFD9bPlsU/"
            maxWidth={480}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          />
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
